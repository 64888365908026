import React, { useEffect, useCallback } from "react";
import { makeStyles } from "../AppContainer/mui-theme";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HomeIcon from "../icons/Home.svg";
import { get, findIndex, map, some, startsWith } from "lodash";
import IceCreamIcon from "../icons/ice-cream.svg";
import NewOrderIcon from "../icons/NewOrder.svg";
import NewOrderPizzaIcon from "../icons/NewOrderPizza.svg";
import ReorderIcon from "../icons/Reorder.svg";
import PizzaReorderIcon from "../icons/PizzaReorder.svg";
import GiftIcon from "../icons/Gift.svg";
import ScanIcon from "../icons/Scan.svg";
import { useLocation } from "@reach/router";
import Badge from "@mui/material/Badge";
import { navigateTo, setParams } from "../../utils/location";
import { LOGIN_TYPES } from "../LoginView";
import { Box } from "@mui/system";

const TabIconWrapper = ({ children }) => {
  const { classes } = useStyles();

  return <span className={classes.icon}>{children}</span>;
};
export default function AppNavigationTabs({
  T,
  appStyles,
  context,
  onOpenMobileAuthView: openMobileAuthView,
  onCloseMobileAuthView: closeMobileAuthView,
}) {
  const tabs = getTabs(appStyles);
  const { classes } = useStyles(tabs.length);

  const [value, setValue] = React.useState(2);

  const navigateToTargetOrLoginMobile = useCallback(
    (target, type) => {
      console.log({ type, target, openMobileAuthView });
      context.user.loggedIn ? navigateTo(target) : openMobileAuthView(type);
    },
    [openMobileAuthView, context.user.loggedIn],
  );

  // const handleChange = (event, newValue) => {};

  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    const tabNextIndex = findIndex(tabs, ({ pathMatch, exact }) =>
      exact
        ? location.pathname === pathMatch
        : some(pathMatch, (path) => startsWith(location.pathname, path)),
    );
    console.log(tabNextIndex);
    setValue(tabNextIndex);
  }, [location]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Tabs
          value={value}
          // onChange={handleChange}
          variant="fullWidth"
          scrollButtons={false}
          aria-label="app-navigator"
          textColor="primary"
          indicatorColor="primary"
        >
          {map(tabs, (tab, index) => (
            <Tab
              key={tab.label}
              wrapped
              className={classes.tab}
              icon={tab.icon(context)}
              label={T(tab.label)}
              aria-label={T(tab.label)}
              onClick={() =>
                tab.onClick &&
                tab.onClick({
                  ...context,
                  navigateToTargetOrLoginMobile,
                  closeMobileAuthView,
                })
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles()((theme, length) => ({
  root: {
    height: 72,
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    background: "white", // modify this for customization of app navigation
    top: "auto",
    bottom: 0,
    left:0,
    width: "100%",
  },
  tab: {
    minWidth: `${100 / length}%`
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 26,
    fill: "currentColor",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
}));

const getTabs = (appStyles) => {
  const { isPizza, isIceCream, disabledFeatures = [] } = appStyles;

  return [
    {
      icon: () => (
        <Box sx={{ mb: 6 }}>
          <TabIconWrapper>
            <HomeIcon />
          </TabIconWrapper>
        </Box>
      ),
      label: "Home",
      pathMatch: "/",
      exact: true,
      onClick: (context) => {
        context.closeMobileAuthView();
        navigateTo("/");
      },
    },
    {
      icon: (context) => (
        <Badge
          badgeContent={get(context, "user.amountOfGiftsUnseen", 0)}
          color="secondary"
        >
          <TabIconWrapper>
            <GiftIcon />
          </TabIconWrapper>
        </Badge>
      ),
      onClick: (context) => {
        context.navigateToTargetOrLoginMobile("/gifts", LOGIN_TYPES.GIFTS);
      },
      label: "Rewards",
      pathMatch: ["/gift"],
    },
    {
      label: "Order",
      icon: (context) => {
        const badgeContent = get(context, "order.orderItems.length", 0);
        const icon = (isPizza && <NewOrderPizzaIcon />) ||
          (isIceCream && <IceCreamIcon />) || <NewOrderIcon />;

        return (
          <Badge
            badgeContent={badgeContent}
            color="secondary"
            variant={"standard"}
          >
            <TabIconWrapper>{icon}</TabIconWrapper>
          </Badge>
        );
      },

      onClick: (context) => {
        context.closeMobileAuthView();
        const { servingOptionType, branchId, orderItems, tableCode } = context.order;
        if (servingOptionType && branchId) {
          navigateTo(setParams("/order", tableCode ? { servingOptionType, branchId, tableCode } : {servingOptionType, branchId}));
        } else {
          if (servingOptionType) {
            navigateTo(setParams("/find-location", { servingOptionType }));
          } else {
            navigateTo("/serving-options");
          }
        }
      },
      pathMatch: ["/serving-options", "/deal", "/order", "/find-location"],
    },
    {
      icon: () => {
        const icon = (isPizza && <PizzaReorderIcon />) || <ReorderIcon />;

        return (
          <Box sx={{ mb: 6 }}>
            <TabIconWrapper>{icon}</TabIconWrapper>
          </Box>
        );
      },
      label: "Reorder",
      onClick: (context) => {
        context.navigateToTargetOrLoginMobile("/reorder", LOGIN_TYPES.REORDER);
      },
      pathMatch: ["/reorder"],
    },
    {
      icon: () => (
        <Box sx={{ mb: 6 }}>
          <TabIconWrapper>
            <ScanIcon />
          </TabIconWrapper>
        </Box>
      ),
      onClick: (context) => {
        context.navigateToTargetOrLoginMobile("/scan", LOGIN_TYPES.SCAN);
      },
      label: "Scan",
      pathMatch: ["/scan"],
    },
  ].filter(({ pathMatch: [path] }) => !disabledFeatures.includes(path.replace("/", "")));
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}
